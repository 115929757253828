const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://saabo.at',
  title: 'SAABO.',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Emrah Bozkaya',
  role: 'solution architect, Liferay expert and IT enthusiast',
  descriptionShort:
    'Software development is my passion. It all started in 1994 when I wrote my first programs with Turbo Pascal on my 486 PC. Since then my enthusiasm for coding and problem solving has not changed. The tools on the other hand have changed a lot.',
  descriptionLong:
    'I support you in the implementation of your software projects, no matter if a small script has to be written or the integration of software in a complex environment has to be done or a company web portal has to be built. Furthermore I offer my expertise in the areas of: software architecture, requirement engineering, software review, moderation, consulting, team leadership, project management and presales.',
  resume: 'https://saabo.at',
  social: {
    linkedin: 'https://www.linkedin.com/in/emrah-bozkaya-0b41261ba/',
    github: 'https://github.com',
  },
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'Portal Project - Website',
    description:
      'For an Austrian telecommunication company.',
    stack: ['Liferay DXP', 'Java', 'React'],
    sourceCode: '',
    livePreview: '',
  },
  {
    name: 'Requirement Engineering Project',
    description:
      'For an Austrian insurance company.',
    stack: ['Mashroom Server', 'TypeScript', 'React'],
    sourceCode: '',
    livePreview: '',
  },
  {
    name: 'Portal Project - Website',
    description:
      'For an Austrian public transport company.',
    stack: ['Liferay DXP', 'Java', 'Angular'],
    sourceCode: '',
    livePreview: '',
  },
  {
    name: 'Portal Project - Self Service Portal',
    description:
      'For an Austrian energy company.',
    stack: ['Liferay 6.2', 'Java', 'AngularJS'],
    sourceCode: '',
    livePreview: '',
  },
  {
    name: 'Project Lead and Architect',
    description:
      'For a german e-mobility company.',
    stack: ['Spring', 'Java', 'Angular'],
    sourceCode: '',
    livePreview: '',
  },
  {
    name: 'For more details, please',
    description:
      'get in touch with me.',
    stack: ['', '', ''],
    sourceCode: '',
    livePreview: '',
  }
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'Java',
  'Java EE',
  'Software Architecture', 
  'Spring',
  'Requirement Engineering',
  'Presales',
  'Liferay',
  'JPA',
  'Hibernate',
  'Consulting', 
  'JS',
  'TypeScript',
  'React',
  'Angular',
  'Software Review', 
  'Git',
  'CI/CD',
  'Jenkins',
  'Team Lead',
  'Agile',
  'Scrum',
  'Scrum Master',
  'Kanban',
  'Moderation', 
  'Team Leadership', 
  'Project Management'
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'emrah.bozkaya@saabo.at',
}

export { header, about, projects, skills, contact }
